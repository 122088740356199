export type BPIOrderStatus = 'partially-invoiced';

export interface BPIOrder {
  id: string;
  status: BPIOrderStatus;
  poNumber: string;
  poDate: string;

  supplier: string;
  supplierAddress: string;
  supplierContact: Contact;

  retailer: string;
  retailerAddress: string;
  retailerContact: Contact;

  shippingAddress: string;

  paymentTerms: string;
  requestedDeliveryDate: string;
  totalPOAmount: number;

  specialInstructions: string[];
  termsAndConditions: string[];

  shippingMethod: string;
  shippingCharges: number;
  taxes: number;

  invoiceIds: string[];
}

export interface Contact {
  name: string;
  email: string;
  phone: string;
}

export type BPIInvoiceStatus = 'paid' | 'disputed';

export interface BPIInvoice {
  id: string;
  status: BPIInvoiceStatus;
  invoiceNumber: string;
  invoiceDate: string;
  poNumber: string;
  poDate: string;
  supplier: string;
  supplierAddress: string;
  retailer: string;
  retailerAddress: string;
  paymentTerms: string;
  dueDate: string;
  totalAmountDue: number;
  shippingCharges: number;
  taxes: number;
  totalAmount: number;
  lineItems: BPIInvoiceLineItem[];
}

export interface BPIInvoiceLineItem {
  id: string;
  description: string;
  quantity: number;
  unitPrice: number;
  total: number;
}

export type BPIPaymentStatus = 'paid' | 'unpaid';

export interface BPIPayment {
  id: string;
  status: BPIPaymentStatus;
  sender: string;
  senderLogo: string;
  amount: number;
}

export const MOCK_BPI_ORDERS: BPIOrder[] = [
  {
    id: '10023',
    status: 'partially-invoiced',
    poNumber: 'PO-10023',
    poDate: '2023-05-03',
    supplier: 'ABC Company',
    supplierAddress: '123 Main St, Anytown, USA',
    retailer: 'XYZ Retail Inc.',
    retailerAddress: '456 Oak Ave, Somewhere, USA',
    shippingAddress: '789 Elm St, Destination, USA',
    paymentTerms: 'Net 30',
    requestedDeliveryDate: '2023-05-20',
    totalPOAmount: 5250,

    specialInstructions: ['Label each box with PO number and item number.', 'Include packing slip with each shipment.'],

    termsAndConditions: [
      'Goods must be delivered by the requested delivery date.',
      'Supplier warrants that all goods are free from defects.',
      'Retailer may return any defective goods for a full refund.',
    ],

    shippingMethod: 'Ground',
    shippingCharges: 150,
    taxes: 350,

    supplierContact: {
      name: 'John Smith',
      email: 'john@abccompany.com',
      phone: '(123) 456-7890',
    },

    retailerContact: {
      name: 'Jane Doe',
      email: 'jane@xyzretail.com',
      phone: '(987) 654-3210',
    },

    invoiceIds: ['5678', '5679'],
  },
];

export const MOCK_BPI_INVOICES: BPIInvoice[] = [
  {
    id: '5678',
    status: 'paid',
    invoiceNumber: 'INV-5678',
    invoiceDate: '2023-05-25',
    poNumber: 'PO-10023',
    poDate: '2023-05-03',
    supplier: 'ABC Company',
    supplierAddress: '123 Main St, Anytown, USA',
    retailer: 'XYZ Retail Inc.',
    retailerAddress: '456 Oak Ave, Somewhere, USA',
    paymentTerms: 'Net 30',
    dueDate: '2021-01-31',
    totalAmountDue: 2375,
    shippingCharges: 75,
    taxes: 175,
    totalAmount: 2125,

    lineItems: [
      {
        id: '1001',
        description: 'Widget A',
        quantity: 50,
        unitPrice: 25,
        total: 1250,
      },
      {
        id: '1002',
        description: 'Widget B',
        quantity: 25,
        unitPrice: 35,
        total: 875,
      },
    ],
  },
  {
    id: '5679',
    status: 'disputed',
    invoiceNumber: 'INV-5679',
    invoiceDate: '2023-06-05',
    poNumber: 'PO-10023',
    poDate: '2023-05-03',
    supplier: 'ABC Company',
    supplierAddress: '123 Main St, Anytown, USA',
    retailer: 'XYZ Retail Inc.',
    retailerAddress: '456 Oak Ave, Somewhere, USA',
    paymentTerms: 'Net 30',
    dueDate: '2021-01-31',
    totalAmountDue: 3375,

    shippingCharges: 75,
    taxes: 175,
    totalAmount: 3125,

    lineItems: [
      {
        id: '1001',
        description: 'Widget A',
        quantity: 50,
        unitPrice: 25,
        total: 1250,
      },
      {
        id: '1002',
        description: 'Widget B',
        quantity: 25,
        unitPrice: 35,
        total: 875,
      },
      {
        id: '1003',
        description: 'Widget C',
        quantity: 20,
        unitPrice: 50,
        total: 1000,
      },
    ],
  },
];

export const MOCK_BPI_PAYMENTS: BPIPayment[] = [
  {
    id: 'PMT-36764',
    status: 'paid',
    sender: 'Target',
    senderLogo: 'https://picsum.photos/seed/123/200',
    amount: 6001.75,
  },
  {
    id: 'PMT-36765',
    status: 'unpaid',
    sender: 'ABC Company',
    senderLogo: 'https://picsum.photos/seed/456/200',
    amount: 2375,
  },
];
